<template>
  <section id="dashboard-ecommerce">
    <b-card no-body class="mb-0">
      <b-card-body class="pb-1">
        <h4 style="font-family: montserrat !important">Hakkımızda</h4>
        <br />
        <p style="font-family: montserrat !important">
          D7K müşteri yönetiminden finansal yönetime, üretim ve stok yönetiminden satış ve tedarik zinciri yönetimine kadar her alanda tam kapsamlı bir yazılım sunar.
          Kalite Standartlarımız
          Tedarikçilerimizi ve iş ortaklarımızı seçerken yüksek standartlara sahibiz. İşimiz birinci sınıf kalitede hizmet sağlamaktır.
          Köklü Geçmişimiz
          15 yıldan fazla tecrübemizle sürekli kendimizi yeniliyoruz. Geleceği yakalamak adına en yenilikçi çözümleri kullanıyoruz.
          Kaliteli Ekibimiz
          Profesyonel bir ekip ile çalışıyoruz. Her türlü sorunuza yanıt vermek için hazırız. Bilgi sistemleri konusunda uzman ekipmanımız ile kurumsal çözümler sunuyoruz. Verimli, kolay ve hızlı karar mekanizmaları sağlıyoruz.
          FAALİYET ALANLARIMIZ
          D7K ERP YAZILIM SİSTEMLERİ VERİ ENTEGRE ÇÖZÜMLERİ LTD. ŞTİ.; 2008 yılından günümüze özelikle Üretim sektörü olmak üzere farklı sektörlerde de faaliyet gösteren firmalara
          Depo Stok Yönetim Sistemleri,
          Üretim Yönetim Sistemleri,
          Müşteri Yönetim Sistemleri(B2B),
          Tedarik Zinciri Yönetim Sistemleri,
          Kalite Yönetim Sistemleri,
          Karar Destek Sistemleri,
          Özel ve Diğer Yazılım Çözümleri (Otomasyon, Entegrasyon, vb.),
          Gibi projeleri tasarlayıp birbirinden bağımsız çalışan sistemleri bir araya getirerek, tek bir sistem halinde bütünleşik olarak çalışmasın sağlamaktadır.
          DEĞERLERİMİZ
          Değişime Uyum – Değişen teknolojiye ve diğer tüm koşullara kolayca uyum sağlamak,
          İş Ahlakı – bütün faaliyetler, kararlar ve ilişkilerde en yüksek ahlaki değerleri gözetmek.
          Gelişim – Yapılan her işi geliştirmek için sürekli gözden geçirmek ve iyileştirmek.
          Çalışanlar – Çalışanlara güven, saygı ile yaklaşmak ve onlara öncülük etmek, Çalışanları kendilerini geliştirmeleri için, eğitim ve gerekli diğer olanakları sağlayarak destelemek.
          Takım Çalışması ve Karar Alma – Kararları, konuya en yakın ve uzaman kişilerce yapılan detaylı araştırma ve analize dayanarak vermek, Bütün işlerde ekip çalışmasından yararlanırken yenilikçilik ve çok sesliliği desteklemek, Bütün ilişkilerde saygılı, nazik ve hoşgörülü davranmaya özen göstermek, Problemler karşısında çözüm odaklı yaklaşım sergilemek.
        </p>
        <br />
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BTableSimple,
  BTr,
  BTbody,
  BTd
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BTableSimple,
    BTr,
    BTbody,
    BTd
  }
};
</script>
